/*
 * @Author: your name
 * @Date: 2022-01-11 16:11:16
 * @LastEditTime: 2022-01-24 14:00:42
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \sifu-official\src\router\index.js
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: (resolve) => require(['@/views/home/index'], resolve),
  },
  {
    path: '/about',
    name: 'about',
    component: (resolve) => require(['@/views/about/index'], resolve),
  },
  // 智慧水利
  {
    path: '/water',
    name: 'water',
    component: (resolve) => require(['@/views/water/index'], resolve),
  },
  // 智慧牧业
  {
    path: '/animal',
    name: 'animal',
    component: (resolve) => require(['@/views/animal/index'], resolve),
  },
  // 智慧林业
  {
    path: '/forestry',
    name: 'forestry',
    component: (resolve) => require(['@/views/forestry/index'], resolve),
  },
  // 智慧商业
  {
    path: '/computer',
    name: 'computer',
    component: (resolve) => require(['@/views/computer/index'], resolve),
  },
  // 数字电商
  {
    path: '/sxj',
    name: 'sxj',
    component: (resolve) => require(['@/views/sxj/index'], resolve),
  },
  // 职业教育
  {
    path: '/code',
    name: 'code',
    component: (resolve) => require(['@/views/code/index'], resolve),
  },
  {
    path: '/product',
    name: 'product',
    component: (resolve) => require(['@/views/product/index'], resolve),
  },
  {
    path: '/smartFarm',
    name: 'smartFarm',
    component: (resolve) => require(['@/views/smartFarm/index'], resolve),
  },
  {
    path: '/join',
    name: 'join',
    component: (resolve) => require(['@/views/join/index'], resolve),
  },
  {
    path: '/detail',
    name: 'detail',
    component: (resolve) => require(['@/views/join/detail'], resolve),
  },
]

const router = new VueRouter({
  routes
})

export default router
